<template>
  <div class="loading"><font-awesome-icon class="icon fa-spin" icon="spinner" /></div>
</template>

<script>
import api from "@/services/api"

export default {
  created() {
    if (this.$route.query.id) {
      api
        .makeDepositPayment(this.$route.query.id)
        .then((res) => {
          var stripe = window.Stripe(
            "pk_live_51IkPDeHTcmDbXTnliOCX6fzPNhLoG34VeoxfOIp2XAArSxKqa9jA6g51CVrpTjVba0H0oRrtYeyE8s05dJUO7GSC00PJFcrURO",
            {
              stripeAccount: res.stripeAccount
            }
          )

          stripe
            .redirectToCheckout({
              sessionId: res.id
            })
            .then(function (result) {
              console.log(result.error.message)
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer
              // using `result.error.message`.
            })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style scoped>
.loading {
  font-size: 6rem;
  text-align: center;
  top: 45%;
  width: 100%;
  position: absolute;
}
</style>
